<template>
  <v-tooltip v-if="icon" left>
    <template #activator="{ on, attrs }">
      <v-btn icon @click="resend" v-bind="attrs" v-on="on">
        <v-icon small>far fa-envelope</v-icon>
      </v-btn>
    </template>
    <span>{{ $t("USER_MANAGEMENT.USER_INVITATIONS.RESEND_ACTION.TITLE") }}</span>
  </v-tooltip>
  <BaseButtonTertiary v-else-if="tertiary" :color="buttonColor" @click="resend" :small="small">
    <v-icon v-if="showButtonIcon" left small> far fa-envelope </v-icon>
    {{ $t("USER_MANAGEMENT.USER_INVITATIONS.RESEND_ACTION.TITLE") }}
  </BaseButtonTertiary>
  <BaseButton v-else :color="buttonColor" @click="resend">
    <v-icon v-if="showButtonIcon" left small> far fa-envelope </v-icon>
    {{ $t("USER_MANAGEMENT.USER_INVITATIONS.RESEND_ACTION.TITLE") }}
  </BaseButton>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UserInvitationResendButtonIcon",
  props: {
    objectId: {
      type: Number,
      required: false,
      default: null,
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    tertiary: {
      type: Boolean,
      required: false,
      default: false,
    },
    showButtonIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonColor: {
      type: String,
      required: false,
      default: () => "primary",
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async resend() {
      this.isLoading = true;

      try {
        await this.$axios.userManagement.resendUserInvitation(this.objectId);

        this.notify({
          type: "success",
          message: "USER_MANAGEMENT.USER_INVITATIONS.RESEND_ACTION.SUCCESS_MESSAGE",
        });
      } catch (err) {
        console.error(err);
        this.notify({
          type: "error",
          message: "USER_MANAGEMENT.USER_INVITATIONS.RESEND_ACTION.ERROR_MESSAGE",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
