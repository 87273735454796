<template>
  <ModuleSubPage fullWidth title="USER_MANAGEMENT.USER_INVITATIONS.LIST.PAGE_TITLE">
    <BaseHasPermission :permissionNames="['invitation.read']">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col
              cols="auto"
              align-self="center"
              class="mr-3 mb-6 mb-md-0"
              v-if="hasPermission({ permissionNames: ['invitation.create'] })"
            >
              <GoToPageButtonIcon
                view-name="management-user-invitation-create"
                :action-name="$t('USER_MANAGEMENT.USER_INVITATIONS.CREATE.ACTION_NAME')"
                icon-name="far fa-plus"
                tertiary
              />
            </v-col>

            <v-spacer />

            <v-col cols="12" md="5" lg="4" xl="3" align-self="center">
              <BaseTableSearchField
                v-model="search"
                @triggerSearch="getUserInvitations"
                :loading="isLoading"
                :disabled="isLoading"
                class="mr-4"
              />
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :loading="isLoading"
            :options.sync="options"
            :footer-props="{
              'items-per-page-options': perPageOptions,
            }"
          >
            <template #[`item.createdAt`]="{ item }">
              {{
                $t("USER_MANAGEMENT.USER_INVITATIONS.LIST.ROWS.LABELS.CLOCK", {
                  time: dateTime(item.createdAt),
                })
              }}
            </template>

            <template #[`item.actions`]="{ item }">
              <v-row no-gutters justify="end">
                <v-col cols="auto" align-self="center">
                  <GoToPageButtonIcon
                    view-name="management-user-invitation-show"
                    :object-id="item.id"
                    :action-name="$t('USER_MANAGEMENT.USER_INVITATIONS.SHOW.ACTION_NAME')"
                    icon-name="far fa-eye"
                    icon
                  />
                </v-col>

                <v-col
                  cols="auto"
                  align-self="center"
                  v-if="hasPermission({ permissionNames: ['invitation.update'] })"
                >
                  <GoToPageButtonIcon
                    view-name="management-user-invitation-edit"
                    :object-id="item.id"
                    :action-name="$t('USER_MANAGEMENT.USER_INVITATIONS.EDIT.ACTION_NAME')"
                    icon-name="far fa-pencil"
                    icon
                  />
                </v-col>

                <v-col
                  cols="auto"
                  align-self="center"
                  v-if="hasPermission({ permissionNames: ['invitation.delete'] })"
                >
                  <DeleteAction
                    :object-id="item.id"
                    :title="$t('USER_MANAGEMENT.USER_INVITATIONS.DELETE_ACTION.TITLE')"
                    :body="$t('USER_MANAGEMENT.USER_INVITATIONS.DELETE_ACTION.BODY')"
                    @confirm="deleteUserInvitation"
                    icon
                  />
                </v-col>

                <v-col cols="auto" align-self="center">
                  <UserInvitationResendButtonIcon :object-id="item.id" icon />
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import dateFilter from "@/mixins/dateFilter";
import DeleteAction from "@components/ButtonIcon/DeleteAction/DeleteAction.vue";
import { mapActions } from "vuex";
import GoToPageButtonIcon from "@components/ButtonIcon/GoToPageButtonIcon.vue";
import UserInvitationResendButtonIcon from "@components/UserInvitation/UserInvitationResendButtonIcon.vue";
import hasPermission from "@mixins/hasPermission";

export default {
  name: "UserInvitationList",

  components: {
    UserInvitationResendButtonIcon,
    GoToPageButtonIcon,
    ModuleSubPage,
    DeleteAction,
  },

  mixins: [dateFilter, hasPermission],

  data() {
    return {
      isLoading: false,
      search: this.$route.query.search ?? null,
      items: [],
      totalItems: 0,
      options: {
        perPage: this.$route.query.perPage ? parseInt(this.$route.query.perPage, 10) : 10,
        page: this.$route.query.page ? parseInt(this.$route.query.page, 10) : 1,
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
        sortBy: this.$route.query.sortBy ?? [],
        sortDesc: this.$route.query.sortDesc ?? [],
      },
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.getUserInvitations();
      },
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("USER_MANAGEMENT.USER_INVITATIONS.LIST.HEADERS.EMAIL"),
          value: "email",
          sortable: true,
        },
        {
          value: "actions",
          sortable: false,
          align: "end",
        },
      ];
    },

    perPageOptions() {
      return [10, 20, 50, 100];
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async getUserInvitations() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.userManagement.listUserInvitations({
          perPage: this.options.perPage,
          page: this.options.page,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          search: this.search,
        });

        this.items = data.data;
        this.totalItems = data.meta.total;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
        this.changeRouteParams();
      }
    },

    async changeRouteParams() {
      try {
        await this.$router.replace({
          query: {
            search: this.search,
            perPage: this.options.perPage,
            page: this.options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
          },
        });
      } catch {
        return;
      }
    },

    async deleteUserInvitation(userInvitationId) {
      this.isLoading = true;

      try {
        await this.$axios.userManagement.deleteUserInvitation(userInvitationId);
        this.notify({
          type: "success",
          message: "USER_MANAGEMENT.USER_INVITATIONS.DELETE_ACTION.SUCCESS_MESSAGE",
        });
      } catch (err) {
        console.error(err);
        this.notify({
          type: "error",
          message: "USER_MANAGEMENT.USER_INVITATIONS.DELETE_ACTION.ERROR_MESSAGE",
        });
      } finally {
        this.isLoading = false;
        this.getUserInvitations();
      }
    },
  },

  created() {
    this.getUserInvitations();
  },
};
</script>
